import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

const Footer = () => (
    <div className="container">
        <footer className="pt-4 my-md-5 pt-md-5 border-top">
            <div className="row">
                <div className="col-12 col-md">
                    <small className="d-block mb-3 text-muted">&copy; Tech Props Designs {moment().format('YYYY')}</small>
                </div>

                <div className="col-6 col-md">
                    <h5>Site Content</h5>
                    <ul className="list-unstyled text-small">
                        <li><Link className="text-muted" to='/'>Home</Link></li>
                        <li><Link className="text-muted" to='/warehouse'>Props &amp; Gak</Link></li>
                        <li><Link className="text-muted" to='/playback'>Playback</Link></li>
                        <li><Link className="text-muted" to='/commissions'>Custom Commissions</Link></li>
                        <li><Link className="text-muted" to='/consulting'>Consulting</Link></li>
                        <li><Link className="text-muted" to='/about'>About</Link></li>
                        <li><Link className="text-muted" to='/contact'>Contact Us</Link></li>
                    </ul>
                </div>
            </div>
        </footer>
    </div>
);

export default Footer;