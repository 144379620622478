import React from 'react';

const About = () => (
    <div className="container">

        <div className="small-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
            <h1 className="display-4">About</h1>
            <p className="lead">

            </p>
        </div>

    </div>
);

export default About;