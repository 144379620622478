import React from 'react';
import { Link, withRouter } from 'react-router-dom';

const Navbar = (props) => {
    const { pathname } = props.location;
    return (
        <nav className="navbar navbar-expand-lg p-3 px-md-4 mb-3 navbar-light bg-white border-bottom shadow-sm">
            <Link className="navbar-brand" to='/' >Tech Props Designs</Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav my-2 my-md-0 ml-md-auto">
                    <NavItem name="Home" to='' pathname={pathname} />
                    <NavItem name="Prop &amp; Gak Warehouse" to='/warehouse' pathname={pathname} />
                    <NavItem name="Playback" to='/playback' pathname={pathname} />
                    <NavItem name="Custom Commissions" to='/commissions' pathname={pathname} />
                    <NavItem name="Consulting" to='/consulting' pathname={pathname} />
                    <NavItem name="About" to='/about' pathname={pathname} />
                    <NavItem name="Contact Us" to='/contact' pathname={pathname} />
                </ul>
            </div>
        </nav>
    )
};

const NavItem = (props) => {
    const { name, pathname, to } = props;
    return (
        <li class={`nav-item${pathname === to ? 'active' : ''}`}>
            <Link className="nav-link" to={to}>{name} {pathname === to ? <span class="sr-only">(current)</span> : null}</Link>
        </li>
    );
}

export default withRouter(props => <Navbar {...props} />);