import React from 'react';
import './Contact.scss';
import { Form } from 'react-bootstrap';

//TODO remove sheet URL from front end code
const sheet_API_URL = 'https://script.google.com/macros/s/AKfycbzWWXS2dN55KjKktdvovaKnmY9Ccda1Q7RDq2aob3F-AVEFI14/exec';

class Contact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            validated: false,
            form_submitted: null
        };
    }

    handleSubmit(event) {
        event.preventDefault();

        const form = event.currentTarget;

        this.setState({
            validated: true
        });

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }

        this.setState({
            form_submitted: false
        });

        const { name, email, subject, message } = form.elements;

        var headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };
        var config = {
            method: 'GET',
            mode: 'no-cors',
            headers: headers
        };

        var url = `${sheet_API_URL}${`?name=${encodeURIComponent(name.value)}&email=${encodeURIComponent(email.value)}&subject=${encodeURIComponent(subject.value)}&message=${encodeURIComponent(message.value)}`}`;

        const self = this;
        window.fetch(url, config)
            .then((response) => {
                console.log('response', response)
                self.setState({
                    form_submitted: true
                });
            });
    }

    render() {
        const { validated, form_submitted } = this.state;
        const { subject } = this.props.match.params;

        return (
            <div className="container" style={{ minHeight: '800px' }}>
                <div className="small-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                    <h1 className="display-4">Contact Us</h1>
                    <p className="lead">
                        Contact Us for more information on all of the items and services we offer.
            </p>
                </div>

                <div className={`row ${form_submitted !== null ? 'visible' : 'hidden'}`}>
                    <div className="col d-flex justify-content-center">
                        <div class={`circle-loader ${form_submitted === true ? 'load-complete' : null}`}>
                            <div class={`checkmark draw ${form_submitted === true ? 'd-block' : null}`}></div>
                        </div>
                    </div>
                </div>

                <div className={`row d-flex flex-row justify-content-center ${form_submitted === null ? 'd-block visible' : 'd-none hidden'}`}>
                    <div className="col-8">
                        <Form validated={validated} onSubmit={this.handleSubmit.bind(this)} noValidate>
                            <div className="form-row">
                                <div className="col">
                                    <div className="form-group">
                                        <label>Name</label>
                                        <input type="text" id='name' className="form-control" required />
                                        <small class="form-text text-muted">Who are you?</small>
                                        <div class="valid-feedback">Looks good!</div>
                                        <div class="invalid-feedback">What... is your name?</div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="col">
                                    <div className="form-group">
                                        <label>Return Email</label>
                                        <input type="email" name='email' className="form-control" required />
                                        <small class="form-text text-muted">How can we respond to you?</small>
                                        <div class="valid-feedback">Looks good!</div>
                                        <div class="invalid-feedback">That's no email address...</div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="col">
                                    <div className="form-group">
                                        <label>Subject</label>
                                        <input type="text" name='subject' className="form-control" defaultValue={subject} required />
                                        <small class="form-text text-muted">What item(s) and / or service(s) are you inquiring about?</small>
                                        <div class="valid-feedback">Looks good!</div>
                                        <div class="invalid-feedback">Help me help you, help you help you help me, - help me help you.</div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="col">
                                    <div className="form-group">
                                        <label>Message</label>
                                        <textarea id='message' className="form-control" rows='5' required />
                                        <small class="form-text text-muted">What would you like to know?</small>
                                        <div class="valid-feedback">Looks good!</div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-row d-flex justify-content-center">
                                <button type="submit" className="btn btn-primary btn-block">Send</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>

        );
    }
};

export default Contact;