import React from 'react';
import Helmet from 'react-helmet';

const Home = () => (
    <div className="container-fluid">
        <div className="small-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
            <h1 className="display-4">What We Do</h1>
            <p className="lead">
                Tech Props Designs is a props house and consulting firm sepcializing in realistic robotics, machinery, computer science, and engineering.
                </p>
        </div>


        <div className="large-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center" style={{ maxWidth: '1020px' }}>
            <h1 className="display-4">Warehouse</h1>

            <p className="lead w-75 mx-auto">
                Tech Props Designs has a warehouse of pre-built technology used for both props or gak available for short notice use.
                </p>

            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                <ol className="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                </ol>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <svg class="bd-placeholder-img bd-placeholder-img-lg d-block w-100" width="800" height="400" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img" aria-label="Placeholder: Second slide">
                            <title>Placeholder</title>
                            <rect width="100%" height="100%" fill="#666"></rect><text x="50%" y="50%" fill="#444" dy=".3em">First slide</text>
                        </svg>
                    </div>
                    <div className="carousel-item">
                        <svg class="bd-placeholder-img bd-placeholder-img-lg d-block w-100" width="800" height="400" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img" aria-label="Placeholder: Second slide">
                            <title>Placeholder</title>
                            <rect width="100%" height="100%" fill="#666"></rect><text x="50%" y="50%" fill="#444" dy=".3em">Second slide</text>
                        </svg>
                    </div>
                    <div className="carousel-item">
                        <svg class="bd-placeholder-img bd-placeholder-img-lg d-block w-100" width="800" height="400" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img" aria-label="Placeholder: Second slide">
                            <title>Placeholder</title>
                            <rect width="100%" height="100%" fill="#666"></rect><text x="50%" y="50%" fill="#444" dy=".3em">Third slide</text>
                        </svg>
                    </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
            </div>

            <div className="mt-3">
                <a type="button" className="btn btn-lg btn-primary" href="/warehouse">See More</a>
            </div>
        </div>


        <div className="large-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
            <h1 className="display-4">Past Shows</h1>

            <div className="row mt-3 d-flex justify-content-around">
                <div className="col-4 h-100">
                    <img className="card-img-top my-auto p-3 w-100" src='/images/shows/the_fosters.png' />
                </div>

                <div className="col-4 h-100">
                    <img className="card-img-top my-auto p-3 w-100" src='/images/shows/modern_family.png' />
                </div>
            </div>
        </div>

        <Helmet>
            <script>{`
            $(function() {
                $('.carousel').carousel()
            });
            `}</script>
        </Helmet>
    </div>
);

export default Home;