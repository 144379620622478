import React from 'react';
import PropTypes from 'prop-types';

const WarehouseItemCard = (props) => {
    const { img, title, description } = props;

    return (
        <div className="col-lg-3 col-4 mb-4">
            <div className="card">
                <img src={img} class="card-img-top" alt="..." style={{ maxHeight: '250px' }} />
                <div class="card-body">
                    <h5 class="card-title">{title}</h5>
                    <p class="card-text">{description}</p>
                    <a href="#" class="btn btn-primary">View</a>
                </div>
            </div>
        </div>
    )
};

WarehouseItemCard.propTypes = {
    img: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
}

WarehouseItemCard.defaultProps = {
    title: "Item Title",
    description: "This is a placeholder description for the item"
}

export default WarehouseItemCard;