import React from 'react';
import PlaybackItemCard from './PlaybackItemCard';

const Playback = () => (
    <>
        <div className="container">

            <div className="small-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                <h1 className="display-4">Playback</h1>
                <p className="lead">

                </p>
            </div>

        </div>
        <div className="container-fluid">

            <div className="small-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                <h1 className="display-5">Programming</h1>
                <p className="lead">

                </p>
            </div>
            <div className="row mb-2">
                <PlaybackItemCard title={'Robotics'} />
                <PlaybackItemCard title={'Web Development'} />
                <PlaybackItemCard title={'Mobile App Development'} />
                <PlaybackItemCard title={'Game Development'} />
            </div>

            <div className="small-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                <h1 className="display-5">Mechanical Drawings &amp; CAD</h1>
                <p className="lead">

                </p>
            </div>

            <div className="row mb-2">
                <PlaybackItemCard reversed={true} title={'Drawing Packages'} />
                <PlaybackItemCard reversed={true} title={'3D CAD'}/>
            </div>
        </div>
    </>
);

export default Playback;