import React from 'react';

const Commissions = () => (
    <div className="container">
        <div className="small-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
            <h1 className="display-4">Custom Commissions</h1>
            <p className="lead">
                Not finding what you are looking for? <a className="font-weight-bolder" href="/contact/Commission">Contact Us</a> to inquire about commissioning a custom piece.
            </p>
        </div>
    </div>
);

export default Commissions;