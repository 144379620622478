import React from 'react';

const Consulting = () => (
    <div className="container">
        <div className="small-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
            <h1 className="display-4">Consulting</h1>
            <p className="lead">
                Our experts are available for consulting on a variety or topics during multiple stages of production. <a className="font-weight-bolder" href="/contact/Consulting">Contact Us</a> to for more information.
        </p>
        </div>


        <div className="small-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
            <h1 className="display-5">Scripts</h1>
            <p className="lead">
                Engineering terms are precise and take years of education and prior knowledge to master.
                Why waste your time learning the difference between a bit and a byte, or a linear actuator and a rotary servo when our experts can help. Our experts are available to proof read scripts or provide deeper consulting of engineering topics and processes before you even start writing.
            </p>
        </div>


        <div className="small-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
            <h1 className="display-5">Set Design</h1>
            <p className="lead">
                What does a scientists lab really look like? How does it differ from a robotics lab? How much organized chaos is too little or too much to be as realistic as possible?
                Our experts live in these environments every day and can help guide you to recreate quickly what takes them months to perfect and then mess up.
            </p>
        </div>
    </div>
);

export default Consulting;