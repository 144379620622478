import React from 'react';
import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Warehouse from './pages/Warehouse';
import Playback from './pages/Playback';
import Commissions from './pages/Commissions';
import Consulting from './pages/Consulting';
import About from './pages/About';
import Contact from './pages/Contact';

function App() {
  return (
    <BrowserRouter>
      <Navbar />

      <Switch>
        <Route path="/contact/:subject?" component={Contact} />
        <Route path='/about' component={About} />
        <Route path='/consulting' component={Consulting} />
        <Route path='/commissions' component={Commissions} />
        <Route path='/playback' component={Playback} />
        <Route path='/warehouse' component={Warehouse} />
        <Route path='/' component={Home} />
      </Switch>

      <Footer />
    </BrowserRouter>
  );
}

export default App;
