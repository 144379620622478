import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const PlaybackItemCard = (props) => {
    const { reversed, title, description } = props;
    return (
        <div className="col-lg-6 col-12 px-lg-2 px-5">
            <div className="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                <div className={`col p-4 d-flex flex-column position-static ${reversed ? 'order-2' : 'order-1'}`}>
                    <h3 className="mb-0">{title}</h3>
                    <p className="card-text mb-auto">{description}</p>
                    <Link className="btn btn-primary stretched-link">More Information</Link>
                </div>
                <div className={`col-auto d-block ${reversed ? 'order-1' : 'order-2'}`}>
                    <svg className="bd-placeholder-img" width="200" height="250" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img" aria-label="Placeholder: Thumbnail"><title>Placeholder</title><rect width="100%" height="100%" fill="#55595c" /><text x="50%" y="50%" fill="#eceeef" dy=".3em">Thumbnail</text></svg>
                </div>
            </div>
        </div>
    );
};

PlaybackItemCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    reversed: PropTypes.bool
}

PlaybackItemCard.defaultProps = {
    title: "Playback Item",
    description: "This is a placeholder description for the playback item.",
    reversed: false
}

export default PlaybackItemCard;