import React from 'react';
import WarehouseItemCard from './WarehouseItemCard';

const Warehouse = () => (
    <>
        <div className="container">
            <div className="small-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                <h1 className="display-4">Warehouse</h1>
                <p className="lead">
                    Browse or Search our warehouse using key terms to easily find what you are looking for.
            </p>
                <div className="">
                    <label>Search</label>
                    <input type="text" className="form-control" placeholder="Search" />
                    <small id="emailHelp" class="form-text text-muted">Enter a key term to search the warehouse.</small>
                </div>
            </div>
        </div>


        <div className="container-fluid">
            <div className="row">
                <WarehouseItemCard img="https://picsum.photos/200/300?random=1" />
                <WarehouseItemCard img="https://picsum.photos/200/300?random=2" />
                <WarehouseItemCard img="https://picsum.photos/200/300?random=3" />
                <WarehouseItemCard img="https://picsum.photos/200/300?random=4" />
                <WarehouseItemCard img="https://picsum.photos/200/300?random=5" />
                <WarehouseItemCard img="https://picsum.photos/200/300?random=6" />
                <WarehouseItemCard img="https://picsum.photos/200/300?random=7" />
            </div>
        </div>
    </>
);

export default Warehouse;